.App {
  /*text-align: center;*/
  display: grid;
  grid-template-columns: 250px auto;
  grid-template-rows: auto;
  grid-template-areas:
    "url url"
    "filters frames"
    "footer footer";
  grid-gap: 10px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 3vh;
  font-size: calc(10px + 0.5vmin);
  font-family: monospace;
  color: white;
}

.App-link {
  color: #61dafb;
}

.urlBar {
  grid-area: url;
}

.urlBar form {
  display: grid;
  grid-template-columns: 12em 50em 10em;
  justify-content: center;
}

.pagination-top {
  grid-area: pagination-top;
}

.pagination-bot {
  grid-area: pagination-bot;
}

.filters {
  grid-area: filters;
}

.footer {
  grid-area: footer;
  text-align: center;
  /*font-weight: lighter;*/
  font-size: 80%;
}

.filterButton {
  background-color: #8cc152; /* Green */
  border: none;
  color: white;
  padding: 7px 15px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.filterButton.Active {
    /*background-color: #2c592c; !* Green *!*/
    filter: brightness(70%);
}

.filterHeader {
  font-weight: bold;
  font-size: 120%;
  text-align: center;
  margin-bottom: 10px;
}

.filterName {
  padding-left: 1em;
}

.filterGroup {
  /*border: 1px solid black;*/
  padding: 5px;
  display: grid;
}

#filterGroup-plugIDs {
  grid-template-columns: repeat(4, 60px);
}

.frameContainer {
  /*display: grid;*/
  grid-area: frames;
  display: grid;
  grid-template-rows: repeat(auto-fill, auto);
  grid-gap: 4px;
  font-family: monospace;
}

.loading {
  display: grid;
  grid-template: 3em 3em / 10em;
  justify-content: center;
  align-items: center;
}

.frame {
  display: grid;
  grid-template-columns: 3em 14em 4em 7em auto;
  grid-gap: 2px;
  grid-template-areas:
    "index timestamp plugid frametype header"
    "index contents contents contents contents";
  border-left: 6px solid black;
  /*border-bottom: 1px solid black;*/
  padding-left: 5px;
  padding-bottom: 5px;
  padding-top: 4px;
}

.frame-top {
  padding-left: 2px;
  padding-bottom: 6px;
  margin-bottom: 6px;
  font-weight: bold;
  border-bottom: 1px solid #c3c3c3;
}

:root {
  --color-netdisco: #967adc;
  --color-netdisco-light: rgba(162, 0, 230, 0.1);
  --color-measurement: #4a89dc;
  --color-measurement-light: rgba(74, 137, 220, 0.1);
  --color-error: #da4453;
  --color-error-light: rgba(218, 68, 83, 0.11);
  --color-command: #e9573f;
  --color-command-light: rgba(184, 134, 11, 0.11);
  --color-broadcast: #37bc9b;
  --color-broadcast-light: rgba(55, 188, 155, 0.11);
  --color-config: #f6bb42;
  --color-config-light: rgba(246, 187, 66, 0.11);

}

.type-NetDisco {
  border-color: var(--color-netdisco);
  background-color: var(--color-netdisco-light);
}

.fb-NetDisco {
  background-color: var(--color-netdisco);
}

.type-Measurement {
  border-color: var(--color-measurement);
  background-color: var(--color-measurement-light);
}

.fb-Measurement {
  background-color: var(--color-measurement);
}

.type-Error {
  border-color: var(--color-error);
  background-color: var(--color-error-light);
}

.fb-Error {
  background-color: var(--color-error);
}

.type-Command {
  border-color: var(--color-command);
  background-color: var(--color-command-light);
}

.fb-Command {
  background-color: var(--color-command);
}

.type-Broadcast {
  border-color: var(--color-broadcast);
  background-color: var(--color-broadcast-light);
}

.fb-Broadcast {
  background-color: var(--color-broadcast);
}

.type-Config {
  border-color: var(--color-config);
  background-color: var(--color-config-light);
}

.fb-Config {
  background-color: var(--color-config);
}

.frame-id {
  font-weight: bold;
  /*border-right: 2px solid black;*/
}


.frame-contents {
  grid-area: contents;
  color: #505050;
}

.payload {
  display: grid;
  grid-template-columns: 15em auto;
}

.pagination-top {
    margin-bottom: 10px;
}

.pagination-bot {
    margin-top: 10px;
}

.paginator {
  display: grid;
  grid-template-rows: 30px;
  grid-template-columns: repeat(auto-fit, 2em);
}

.paginator-button {
  border: 1px solid gray;
  text-align: center;
  cursor: pointer;
}

.paginator-button.active {
  color: #eeeeee;
  background-color: #4a89dc;
}

.paginator-button:hover {
    background-color: rgba(74, 137, 220, 0.47);
}






@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #0900ff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

